import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, CheckboxField, SelectField } from '../../FormFields';


export default function AddressForm(props) {
  const {
    formField: {
      firstName,
      emailAddr,
      phone,
      matricula,
      privacyPolicy1,
      privacyPolicy2
    }
  } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Preencha as informações
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField
            variant="outlined"
            name={firstName.name}
            label={firstName.label}
            fullWidth
            autoComplete="name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            variant="outlined"
            name={emailAddr.name}
            label={emailAddr.label}
            fullWidth
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            variant="outlined"
            name={phone.name}
            label={phone.label}
            fullWidth
            autoComplete="phone"
          />
        </Grid>
        {/*  <Grid item xs={12} sm={6}>
          <SelectField
            name={city.name}
            label={city.label}
            data={cities}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={state.name}
            label={state.label}
            data={states}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <InputField
            variant="outlined"
            name={matricula.name}
            label={matricula.label}
            fullWidth
            autoComplete="matrícula"
          />
        </Grid>
        {/*  <Grid item xs={12} sm={6}>
          <SelectField
            name={hourPick.name}
            label={hourPick.label}
            data={countries}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12}>
          <CheckboxField
            name={privacyPolicy1.name}
            label={privacyPolicy1.label}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            name={privacyPolicy2.name}
            label={privacyPolicy2.label}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
