/* eslint-disable react/prop-types */
import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const renderMarkers = (map, maps) => {
  let marker = new maps.Marker({
  position: { lat: 40.632574, lng: -7.904688 },
  map,
  title: 'CiViseu - Centro de Inspecções !'
  });
  return marker;
 };





class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 40.632574,
      lng: -7.904688
    },
    zoom: 16
  };

  

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "50vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAvhq73KBmnPltJ-PkfZ_G4ClrC_lhjjdw" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
         onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <AnyReactComponent lat={40.632574} lng={-7.904688} text="CiViseu" />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
