import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Ligeiros", "€ 35,89"),
  createData("Pesados", "€ 53,73"),
  createData("Reboques e Semi Reboques", "€ 35,89"),
  createData("Reinspeções de inspeções periódicas obrigatórias", "€ 8,99"),
  createData("Motociclos, Triciclos e Quadriciclos ", "€ 18,08")
];
const rows2 = [
  createData("Atribuição/Reposição de Matricula ", "€ 89.59"),
  createData("Reinspeções de atribuição de Matricula ", "€ 42.68"),
  createData("Acidentados", "€ 125,31"),
  createData("Adaptação ao GPL", "€ 125,31"),
  createData("Aplicação de Películas", "€ 125,31"),
  createData("Determinação de CO2", "€ 125,31"),
  createData("Classificação de Portagens ", "€ 125,31"),
  createData("Reposição de Matrículas Canceladas", "€ 125,31"),
  createData("Inspeção para Transporte Coletivo de Crianças (TCC)", "€ 125,31"),
  createData("Transformação Veículos N1 para M1 ", "€ 125,31"),
  createData("Reinspeções extraordinárias e facultativas", "€59,69"),
  createData("Emissão de 2ª Via", "€ 3,37"),
  createData("Outras Inspeções Determinadas Pelo Imt.Ip", "€ 39,68")
];

const useStyles = makeStyles({
  table: {
    minWidth: 500
  }
});

export default function CustomizedTables() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table
        className={classes.table}
        size="small"
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Inspecção Periódica Obrigatória</StyledTableCell>
            <StyledTableCell align="left">Preço</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">{row.calories}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow>
            <StyledTableCell>Inspecção Extraordinária</StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows2.map(row => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">{row.calories}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
