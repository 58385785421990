/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload,Schedule } from "@material-ui/icons";
import InfoIcon from '@material-ui/icons/Info';
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Mais informações"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={InfoIcon}
          dropdownList={[
           
            <Link
              to = "/info"
              className={classes.dropdownLink}
            >
              Informações
            </Link>,
            <Link
            to = "/contactos"
            className={classes.dropdownLink}
          >
            Contactos
          </Link>
            
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
      <Link to={"/"} className={classes.navLink} onClick={props.scroll}>
        
          <Schedule className={classes.icons} /> Marcar Inspecção
       
        </Link>

        
      </ListItem>
      
     
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Segue-nos no facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/civiseu.pt/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Segue-nos no instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/civiseu/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
