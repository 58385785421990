/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import ReactDOM from "react-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
import MetaTags from "react-meta-tags";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line no-unused-vars

// @material-ui/icons
import { Link } from "react-router-dom";
import Warning from "@material-ui/icons/Warning"; // @material-ui/icons
import Img from "assets/img/logocentro_small.png";

// core components
import Header from "components/Header/Header.js";
import MaterialLayout from "components/Layout/MaterialLayout";
import CheckoutPage from "components/CheckoutPage";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
import Parallax from "components/Parallax/Parallax.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import { useForm, Controller } from "react-hook-form";

// Sections for this page

import ProductSection from "./Sections/ProductSection.js";
//import TeamSection from "./Sections/TeamSection.js";
import { Check, TodayOutlined } from "@material-ui/icons";
import SectionDownload from "./Sections/SectionDownload.js";
//import TeamSection from "./Sections/TeamSection.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";

//Scroll para componenete
const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();

  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const { ...rest } = props;
  return (
    <div>
      <MetaTags>
        <title>CiViseu</title>
        <meta
          name="CiViseu"
          content="O centro com maior capacidade inspectiva de Viseu já voltou a abrir.
    Marque já a sua inspecção e conduza com segurança. Faça já a sua inspecção
    no centro de Viseu sem filas e sem demorar mais de dez minutos."
        />
      </MetaTags>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img alt="logo da CiViseu" src={Img} />}
        rightLinks={<HeaderLinks scroll={executeScroll} />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "dark"
        }}
        {...rest}
      />

      <Parallax filter image={require("assets/img/bg4.jpeg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>CiViseu</h1>
              <h3>
                Sem filas, sem esperas e com segurança. O maior centro de
                inspecções de Viseu reabriu junto às piscinas de cabanões.
              </h3>
              <br />
              <GridItem md={12} className={classes.textCenter}>
                <Button
                  color="success"
                  size="lg"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={executeScroll}
                >
                  <i className="fas fa-clock" />
                  Marcar Inspecção
                </Button>
              </GridItem>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main)} ref={myRef}>
        {/*  <SnackbarContent
            message={
              <span>
                <b>AVISO:</b> Devido às novas medidas implementadas pelo Governo
                através do Decreto de Lei 3-C-2021, as inspeções só poderão ser
                realizadas mediante marcação prévia. Obrigado.
              </span>
            }
            close
            color="danger"
            icon={Warning}
          /> */}

        <br></br>

        <MaterialLayout>
          <CheckoutPage />
        </MaterialLayout>

        <SectionCarousel />

        <div className={classNames(classes.container)}>
          <ProductSection />
        </div>

        <SectionDownload scroll={executeScroll} />
      </div>
      <Footer />
    </div>
  );
}
