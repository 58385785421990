/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Img from "assets/img/logocentro_small.png";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import WorkSection from "./WorkSection.js";
import Mapas from "./GoogleMaps.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
const useStyles = makeStyles(styles);

export default function Contactos(props) {
  const classes = useStyles();
  return (
    <div>
      <Header
        color="dark"
        brand={<img src={Img} />}
        rightLinks={<HeaderLinks />}
      />

      <div className={classNames(classes.main)}>
        <Mapas />
        <div className={classNames(classes.container)}>
          <WorkSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
