/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// core components
import styles from "assets/jss/material-kit-react/views/componentsSections/downloadStyle.js";

const useStyles = makeStyles(styles);

export default function SectionDownload(props) {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer className={classes.textCenter} justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2>Obrigado por escolher a CiViseu!</h2>
            <h4>
            Escolha o dia e a hora que mais lhe convém,
             estaremos aqui para garantir que a sua viatura está operacional 
             para desfrutar dela ao máximo!
            </h4>
          </GridItem>
          <GridItem xs={12} sm={8} md={6}>
            <Button
              color="success"
              size="lg"
              target="_blank"
              onClick = {props.scroll}
            >
              Agendar Inspecção
            </Button>
           
          </GridItem>
        </GridContainer>
        <br />
        <br />
        
        <div className={classes.textCenter + " " + classes.sharingArea}>
          <GridContainer justify="center">
            <h3>Siga-nos nas redes sociais.</h3>
          </GridContainer>
          
          <Button color="facebook" href="http://www.facebook.com/civiseu.pt">
            <i className={classes.xxx + " fab fa-facebook-square"} />   
           
          </Button>
          <Button color="primary"href="http://www.instagram.com/civiseu">
            <i className={classes.socials + " fab fa-instagram"} /> 
          </Button>
      
         
        </div>
      </div>
    </div>
  );
}
