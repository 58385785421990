import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import Informacoes from "views/LandingPage/Informacoes.js";
import Contactos from "views/Components/Contactos/Contactos.js";
import Terms from "views/Components/Terms/Terms.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/info" component={Informacoes} />
      <Route path="/contactos" component={Contactos} />
      <Route path="/termos" component={Terms} />
      <Route path="/agendarinspeccao" component={LandingPage} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
