/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/Inbox';




// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {

  const PORT = process.env.PORT || 3000;
  var isLocal;
  const env = `https://www.civiseu.pt`;
  const local = `http://localhost:${PORT}`;

if(process.env.NODE_ENV === 'production'){

  console.log("zas");
  isLocal = env;
 
}else {
  console.log("not zas");
  isLocal = local;
}

  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.livroreclamacoes.pt/inicio"
                className={classes.block}
                target="_blank"
              >
            
            <img alt="Logo do Livro de reclamações" src={require('./reclama.png')} />
            
              </a>
            </ListItem>
            
            <ListItem className={classes.inlineBlock}>
        
              <a
                className={classes.block}
                target="_blank"
                href={`${isLocal}/termos`}
              >
                Termos e Condições
              </a>
     
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://www.facebook.com/civiseu.pt/"
                className={classes.block}
                target="_blank"
              >
                Contacte-nos
              </a>
            </ListItem>
          
          </List>
        </div>
        <div className={classes.right}>
          Copyright &copy; CiViseu, V.03 {1900 + new Date().getYear()} 
          
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
