import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, DatePickerField } from '../../FormFields';

export default function PaymentForm(props) {
  const {
    formField: { nameOnCard, cardNumber, datePick, cvv }
  } = props;

  

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Escolha um dia
      </Typography>

      <Grid item xs={12} md={6}>
        <DatePickerField
          name={datePick.name}
          label={datePick.label}
          fullWidth
        />
      </Grid>
    </React.Fragment>
  );
}
