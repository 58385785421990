import * as Yup from "yup";
import moment from "moment";
import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    firstName,
    emailAddr,
    phone,

    matricula,
    hourPick,
  
    datePick,

    privacyPolicy1,
    privacyPolicy2
  }
} = checkoutFormModel;

const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const PhoneRegex = /^([+]?\d{1,5}[-\s]?|)\d{3}[-\s]?\d{3}[-\s]?\d{3}$/;

//FIXME: Trocar ordem dos objectos pela ordem em que aparecem

export default [
  Yup.object().shape({
    /*   [nameOnCard.name]: Yup.string().required(`${nameOnCard.requiredErrorMsg}`),
    [cardNumber.name]: Yup.string()
      .required(`${cardNumber.requiredErrorMsg}`)
      .matches(visaRegEx, cardNumber.invalidErrorMsg), */
    [datePick.name]: Yup.string()
      .nullable()
      .required(`${datePick.requiredErrorMsg}`)
    /* .test('expDate', datePick.invalidErrorMsg, val => {
        if (val) {
          const startDate = new Date();
          const endDate = moment(new Date()).add(1, 'year');
          if (moment(val, moment.ISO_8601).isValid()) {
            console.log(endDate);
            return moment(val).isBetween(startDate, endDate);
          }
          return false;
        }
        return false;
      }) */
    /*   [cvv.name]: Yup.string()
      .required(`${cvv.requiredErrorMsg}`)
      .test('len', `${cvv.invalidErrorMsg}`, val => val && val.length === 3) */
  }),

  Yup.object().shape({
    [hourPick.name]: Yup.string()
      .nullable()

      .required(`${hourPick.requiredErrorMsg}`)
  }),

  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [emailAddr.name]: Yup.string()
      .matches(emailRegEx, "Introduzir email válido")
      .required(`${emailAddr.requiredErrorMsg}`),
    [phone.name]: Yup.string()
      .matches(PhoneRegex, "Introduzir número válido")
      .required(`${phone.requiredErrorMsg}`),
    /*  [city.name]: Yup.string()
      .nullable()
      .required(`${city.requiredErrorMsg}`), */
    [matricula.name]: Yup.string().required(`${matricula.requiredErrorMsg}`),
    /*   [hourPick.name]: Yup.string()
      .nullable()
      .required(`${hourPick.requiredErrorMsg}`) */
    [privacyPolicy1.name]: Yup.boolean()
      .required("Os termos e condições tem que ser aceites.")
      .oneOf([true], "Os termos e condições tem que ser aceites."),

    [privacyPolicy2.name]: Yup.boolean()
      .required("Os termos e condições tem que ser aceites.")
      .oneOf([true], "Os termos e condições tem que ser aceites.")
  })
];
