import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons

// core components
import FormControl from "@material-ui/core/FormControl";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// core components

import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PlaceIcon from '@material-ui/icons/Place';
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);



export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Contacte-nos</h2>
          <h4 className={classes.description}>
            Envie-nos uma mensagem no caso de alguma duvida. Pode contactar pelo facebook ou por algum dos contactos disponíveis em baixo. 

          </h4>

       
   {/*        <form>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="O seu nome"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="O seu e-mail"
                  id="email"
                  color ="sucess"
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <CustomInput
                labelText="A sua mensagem"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />

              <GridContainer >
                <GridItem xs={12} sm={12} md={6} className={classes.textCenter}>
                  <Button color="success">Enviar Mensagem</Button>
                </GridItem>
              </GridContainer>
            </GridContainer>
          </form> */}
          
        </GridItem>
      </GridContainer>
        <br></br>
        <br></br>
        <br></br>
      <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="(+351) 232 469 037 "
              icon={PhoneIcon}
              iconColor="success"
              vertical
            /><p className={classes.description}>
           Chamada para a Rede Fixa Nacional</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="civiseu@civiseu.pt"
              icon={EmailIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Estrada Cabanões 3500-322 Viseu "
              icon={PlaceIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
    </div>
  );
}
