import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import MaterialLayout from "components/Layout/MaterialLayout";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",

    backgroundColor: theme.palette.background.paper
  }
}));

export default function CheckboxList() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const list = [
    {
      id: "a",
      firstname: "Robin",
      lastname: "Wieruch",
      year: 1988
    },
    {
      id: "b",
      firstname: "Dave",
      lastname: "Davidds",
      year: 1990
    }
  ];

  return (
  
    <MaterialLayout>

  <h2>Para preparar a sua inspecção siga estas recomendações:</h2>  
      <List className={classes.root}>
        {[
          "Verifique luzes de presença, médios, máximos e toda a restante sinalização luminosa;",
          "Verificar o correcto funcionamento dos limpa pára-brisas;",
          "Veja o funcionamento dos espelhos retrovisores:interiores e exteriores; ",
          "Confira o estado dos pneus;",
          "Verifique se tem colete refletor e triângulo de sinalização;;"
        ].map(value => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            <ListItem
              key={value}
              role={undefined}
              dense
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText primary={`${value}`} />
            </ListItem>
          );
        })}
      </List>
    </MaterialLayout>
  );
}
