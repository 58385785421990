/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
// nodejs library that concatenates classes
import classNames from "classnames";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import { Link } from "react-router-dom";
import Warning from "@material-ui/icons/Warning"; // @material-ui/icons
import Img from "assets/img/logocentro_small.png";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
const useStyles = makeStyles(styles);

export default function Contactos(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="dark"
        brand={<img src={Img} />}
        rightLinks={<HeaderLinks />}
    
        {...rest}
      />

      <div className={classNames(classes.main)}>
    

        <GridContainer className={classes.textCenter} justify="center" >
          <GridItem xs={12} sm={12} md={8}>
            <h2>POLÍTICA DE PRIVACIDADE DE TRATAMENTO DE DADOS DE CLIENTES</h2>
            <h4>
     

A Política de Privacidade estabelecida no presente documento aplica-se a todos os dados de carácter
pessoal que o “Utilizador” disponibilize à FTP-SERVIÇOS, LDA, com sede e estabelecimento (CIVISEU -
CITV 248) em Estrada de Cabanões, Ranhados, 3500-322 em Viseu, através de formulários online ou ao
realizar ou ao solicitar a prestação de um serviço na página Web da CIVISEU.
Tendo em vista os efeitos da presente Política da Privacidade, entende-se como “Utilizador” qualquer
pessoa singular interessada nos serviços que a FTP-Serviços, Lda/ CIVISEU oferece por meio da sua
página Web.
Como “Responsável pelo Tratamento” a nossa empresa solicitará previamente à apresentação dos seus
dados de carácter pessoal o seu consentimento expresso enquanto “Utilizador” sobre a sua Política de
Privacidade quando se mostrar necessário e em qualquer aspecto que requeira a sua autorização prévia.

<h3>
    A informação pessoal que recolhemos inclui:
</h3>
<h4>
    ▪ o seu nome e apelidos e informações de contacto (morada, números de telefone e de endereço de
    email), bem como informações sobre a sua empresa ou entidade empregadora, se o seu pedido for
    feito em nome de uma empresa; <br></br>
    ▪ informações sobre os serviços pelos quais se interessa ou que adquiriu; <br></br>
    ▪ informações necessárias para o seu financiamento, tais como informações sobre pagamentos. 
</h4>

</h4>
<h4>
<h3>
    Com a autorização do “Utilizador “ a sua informação pessoal poderá ser partilhada e comunicada
    a:
</h3>
▪ Empresas ou outras organizações com as quais nos tiver solicitado ou aceite que possamos partilhar
a sua informação pessoal; <br></br>
▪ Aos nossos colaboradores, para lhe prestar o serviço solicitado; <br></br>
▪ Prestadores de serviços profissionais; <br></br>
▪ Organismos públicos, tribunais, reguladores e outras autoridades administrativas ou outros terceiros,
quando consideremos que é necessário cumprir uma obrigação legal ou regulatória, ou de outra
forma, nos protegermos de reclamações, ou para a segurança das pessoas, bem como para prevenir,
ou eliminar eventuais situações de fraude, por razões de segurança ou de protecção.


<h3>
    A informação pessoal que recolhemos será tratada para:
</h3>
▪ Garantir ao utilizador o acesso e a utilização do seu espaço pessoal na área privada.
▪ Gerir pedidos do Utilizador.
▪ Informarmos o Utilizador sobre os nossos serviços, incluindo o envio de comunicações comerciais,
bem como realizar inquéritos; <br></br>
▪ Cumprir obrigações legais, responder a pedidos de informação em caso de processos judiciais ou
requerimentos de autoridades administrativas ou de terceiros, para detetar e prevenir situações de
fraude, ou proteger os seus interesses, os nossos ou os de terceiros; <br></br>
Apenas serão tomadas decisões automatizadas sobre si quando nos tiver dado a sua autorização para
podermos tomar uma decisão automatizada e as decisões forem autorizadas nos termos legais ou forem
necessárias para cumprir ou subscrever uma prestação de serviços consigo.
Poderá contactar connosco para mais informação acerca das decisões automatizadas e de outros
aspectos e, em determinados casos, opor-se às mesmas ou solicitar que as decisões automatizadas
sejam revistas por uma pessoa.


</h4>

<h4>


<h3>
    A legitimidade para o tratamento dos dados pessoais do “Utilizador” pode ter por base:
</h3>
▪ A sua necessidade para a execução de uma prestação de serviços no qual for parte ou no quadro e
com vista à eventual formação de uma relação contratual; <br></br>
▪ A sua necessidade para a satisfação dos nossos legítimos interesses, como para lhe enviar ou
mostrar informação, ofertas e anúncios online destes serviços para garantir que são executados e 
entregues em conformidade com a lei e nos termos e condições aplicáveis aos mesmos e quando tal
for necessário para proteger e defender os nossos direitos ou bens, ou os de terceiros, bem como
para detectar, prevenir ou gerir situações de fraude, segurança, protecção ou privacidade; <br></br>
▪ A sua necessidade para tratar a sua informação pessoal para cumprir uma obrigação legal ou
regulamentar, que estiver em vigor em cada momento; <br></br>
▪ A sua autorização para, nomeadamente, utilizar a sua informação pessoal para lhe enviarmos
comunicações comerciais por correio electrónico ou mensagens de texto, excepto se se opuser a
essa utilização, na forma prevista para o efeito. Quando nos basearmos na sua autorização para
utilizar a sua informação pessoal, terá direito a retirar a mesma em qualquer momento.



<h3>
    Prazo de Conservação de Dados Pessoais
</h3>
Conservaremos os seus dados pessoais enquanto durar a sua relação contratual e/ou o seu
consentimento.
Não obstante, os seus dados pessoais podem ser mantidos posteriormente por razões de natureza legal
ou para assegurar o exercício ou a defesa de potencias reclamações e sempre que a legislação que for
aplicável o permitir.


</h4>


<h4>


<h3>Direitos dos Utilizadores</h3> 

O “Utilizador” tem a possibilidade de exercer os direitos de Acesso, Rectificação, Oposição,
Supressão, Portabilidade e Limitação do Tratamento, assim como a Recusar o Tratamento
Automatizado dos dados pessoais recolhidos pela FTP-Serviços, Lda.
Tais direitos poderão ser exercidos gratuitamente pelo “Utilizador”, através de pedido escrito, assinado e
dirigido à FTP-Serviços, Lda para a seguinte morada: Estrada de Cabanões, 3500-322, Ranhados, Viseu,
contendo como dados: o nome e apelidos do Utilizador, domicilio para efeito de notificações, cópia do Cartão de Cidadão ou Passaporte e texto a concretizar o pedido.

A revogação do consentimento prestado para o exercício de informações comerciais pode ser efectuada
em qualquer momento pelo “Utilizador” com uma simples notificação à FTP-Serviços, Lda pela qual
informa que não deseja continuar a receber informações comerciais.
Nesse sentido, o Utilizador também pode revogar o seu consentimento clicando no link incluído em cada
comunicação comercial, cancelando o envio de comunicações electrónicas.
Responsável pelo Tratamento
Miguel Simões
Sérgio Fernandes
Correio eletrónico: CIVISEU@CIVISEU:PT
Telefone: 232 469 037
Quando tiver alguma reclamação relativamente à utilização da sua informação pessoal da nossa parte
gostaríamos que se dirigisse a nós em primeiro lugar. Se não tiver ficado satisfeito relativamente ao
exercício dos seus direitos, poderá apresentar uma reclamação perante uma autoridade de controlo (em
Portugal será a Comissão Nacional de Proteção de Dados: http://www.cnpd.pt).

</h4>

<h4>
<h3>
    Segurança
</h3>
A FTP-serviços, Lda compromete-se ao cumprimento da sua obrigação de sigilo dos dados de carácter
pessoal e do seu dever de os guardar, adoptando as medidas necessárias para evitar a sua alteração,
perda, tratamento ou acesso não autorizado, de acordo com a legislação aplicável.
Actualizações da presente Comunicação
Poderemos alterar periodicamente a nossa Política de Privacidade. Se modificarmos esta notificação,
iremos notificá-lo desse facto. Se estas alterações causarem um grande impacto no tipo de tratamento
que realizamos ou na sua pessoa, informá-lo-emos em tempo útil para que possa exercer os seus direitos
em relação à sua informação pessoal (por exemplo, se quiser usá-la). 

            </h4>


            <br></br>
          </GridItem>
 

        </GridContainer>

        

      </div>
      <Footer />
    </div>
  );
}
