/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Img from "assets/img/logocentro_small.png";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

import HeaderLinks from "components/Header/HeaderLinks.js";

import SectionPills from "./Sections/SectionPills2.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
//import TeamSection from "./Sections/TeamSection.js";
//import TeamSection from "./Sections/TeamSection.js";

//TODO: Por link no logo pagina principal

//TODO:

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Informacoes(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="dark"
        routes={dashboardRoutes}
        brand={<img src={Img} />}
        rightLinks={<HeaderLinks />}
        fixed
        {...rest}
      />

      <div className={classNames(classes.main)}>
        <div className={classNames(classes.container)}></div>

        <SectionPills />
      </div>
      <Footer />
    </div>
  );
}
