import React from "react";
import { Typography } from "@material-ui/core";

function CheckoutSuccess(props) {
  var stringval = "";
  var stringval2 = "";

  if (props.codigo == "INVALIDA") {
    stringval = "A sua inspecção é:  ";
    stringval2 =
      "Reveja se já nao marcou inspecção, verfique o seu email ou recarregue o site e tente outra vez.";
  } else {
    stringval = "Marcação efectuada com o código de marcação: ";
    stringval2 =
      ". Vai receber um email com a confirmação da sua marcação. Até já";
  }

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Obrigado por escolher a CiViseu.
      </Typography>
      <Typography variant="subtitle1">
        {stringval} {props.codigo} {stringval2}
      </Typography>
    </React.Fragment>
  );
}
export default CheckoutSuccess;
