import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SectionTabela from "./TabelaPreco.js";
import Schedule from "@material-ui/icons/Schedule";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import DescriptionIcon from "@material-ui/icons/Description";
import UpdateIcon from "@material-ui/icons/Update";
import CheckList from "./CheckList.js";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MaterialLayout from "components/Layout/MaterialLayout";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionPills() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div id="navigation-pills">
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={10}>
              <h2 className={classes.title}>Informações</h2>

              <NavPills
                color="success"
                tabs={[
                  {
                    tabButton: "PREÇO",
                    tabIcon: EuroSymbolIcon,
                    tabContent: (
                      <span>
                        <SectionTabela />
                      </span>
                    )
                  },
                  {
                    tabButton: "HORÁRIO",
                    tabIcon: Schedule,
                    tabContent: (
                      <span>
                            <MaterialLayout>
                        <h2>
                          ⏰<b> Segunda: </b> 8:00h às 19:00h <br />
                          ⏰ <b> Terça: </b> 8:00h às 19:00h <br />
                          ⏰ <b> Quarta: </b> 8:00h às 19:00h <br />
                          ⏰ <b> Quinta: </b> 8:00h às 19:00h <br />
                          ⏰ <b> Sexta: </b> 8:00h às 19:00h <br />
                          ⏰ <b> Sábado: </b> 8:00h às 13:00h <br />
                        </h2>
                        </MaterialLayout>
                        <br />
                      </span>
                    )
                  },
                 
                  {
                    tabButton: "CheckList",
                    tabIcon: CheckBoxIcon,
                    tabContent: (
                      <span>
                        <CheckList align="center" />
                      </span>
                    )
                  },
                  {
                    tabButton: "DOCUMENTOS",
                    tabIcon: DescriptionIcon,
                    tabContent: (
                      <span>
                        <div className={classes.typo}>
                          <h6>
                            1 - Livrete e Título de Registo de Propriedade ou
                            Documento Único Automóvel e Ficha da última inspeção
                          </h6>
                          No ato da inspeção periódica deve o apresentante do
                          veículo exibir os documentos previstos no n.º 2 do
                          artigo 85.º do Código da Estrada, sem os quais a
                          inspeção não pode ser efetuada. Livrete e Título de
                          Registo de Propriedade ou Documento Único Automóvel
                          (DUA) Ficha da última inspeção - exceto na 1ª
                          inspeção.
                          <br></br>
                          <h6>
                            2 - Documento de substituição dos documentos de
                            identificação do veículo{" "}
                          </h6>{" "}
                          Pode ser realizada a inspeção mediante a apresentação
                          de documento de substituição dos documentos de
                          identificação do veículo, nos termos a definir por
                          deliberação do conselho diretivo do IMT, I. P., desde
                          que o centro de inspeções possa confirmar por via
                          electrónica, na base de dados de veículos do IMT, I.
                          P., a conformidade das características do veículo, com
                          o constante no documento de substituição apresentado.
                          <br></br>
                          <h6>
                            {" "}
                            3 - Documentos referidos no n.º 2 do artigo 85.º do
                            Código da Estrada{" "}
                          </h6>{" "}
                          Nas inspeções extraordinárias devem ser apresentados
                          os documentos referidos no n.º 2 do artigo 85.º do
                          Código da Estrada, salvo se estiverem apreendidos,
                          devendo, neste caso, ser substituídos pelo documento
                          de substituição previsto no número anterior.
                          <br></br>
                          <h6>
                            {" "}
                            4 - Nova matrícula devem ser apresentados os
                            documentos respeitantes ao veículo{" "}
                          </h6>{" "}
                          Nas inspeções para atribuição de nova matrícula devem
                          ser apresentados os documentos respeitantes ao
                          veículo, nos termos e condições previstos em
                          legislação específica.
                          <br></br>
                          <h6>
                            {" "}
                            5 - Inscrição clara do número do quadro do veículo
                          </h6>{" "}
                          Qualquer documento de identificação de um veículo só
                          pode ser aceite por um centro de inspeções desde que
                          contenha a inscrição clara do número do quadro do
                          veículo, sendo nulo qualquer ato inspetivo que tenha
                          por base um documento de identificação de um veículo
                          que não apresente o respetivo número de quadro.
                        </div>
                      </span>
                    )
                  }/* ,
                  {
                    tabButton: "PERIODICIDADE",
                    tabIcon: UpdateIcon,
                    tabContent: (
                      <span>
                        <SectionTabela />
                      </span>
                    )
                  } */
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
