import checkoutFormModel from "./checkoutFormModel";
const {
  formField: {
    firstName,
    emailAddr,
    phone,
    matricula,
    hourPick,
    privacyPolicy1,
    privacyPolicy2,
    datePick
    
  }
} = checkoutFormModel;

export default {
  [firstName.name]: "",
  [emailAddr.name]: "",
  [phone.name]: "",
  [matricula.name]: "",
  [privacyPolicy1.name]: false,
  [privacyPolicy2.name]: false,
  [datePick.name]: ""
};
