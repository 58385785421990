export default {
  formId: "checkoutForm",
  formField: {
    firstName: {
      name: "firstName",
      label: "Nome*",
      requiredErrorMsg: "Nome obrigatório"
    },
    emailAddr: {
      name: "emailAddr",
      label: "Email*",
      requiredErrorMsg: "Email obrigatório"
    },
    phone: {
      name: "phone",
      label: "Telemóvel*",
      requiredErrorMsg: "Número obrigatório"
    },
    matricula: {
      name: "matricula",
      label: "Matrícula*",
      requiredErrorMsg: "Matrícula obrigatória",
      invalidErrorMsg: "matricula is not valid (e.g. 70000)"
    },
    hourPick: {
      name: "hourPick",
      label: "Hora*",
      requiredErrorMsg: "Hora obrigatória",
      feriadoErrorMsg: "É Feriado. Por favor escolha outro dia."
    },
    privacyPolicy1: {
      name: "privacyPolicy1",
      label:
        "Autorizo a CiViseu a utilizar os dados recolhidos para fins de comunicação e promoção."
    },
    privacyPolicy2: {
      name: "privacyPolicy2",
      label:
        "Autorizo pelo presente a recolha, processamento e utilização dos meus dados pessoais de acordo com a Política de Privacidade. Esta autorização poderá ser retirada a qualquer momento."
    },
    datePick: {
      name: "datePick",
      label: "Expiry date*",
      requiredErrorMsg: "Data obrigatória",
      invalidErrorMsg: "Expiry date is not valid"
    }
  }
};
