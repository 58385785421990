import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import YouTube from "react-youtube-embed";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import BuildIcon from "@material-ui/icons/Build";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <h2 className={classes.title}>A Nossa Política</h2>
          <h5 className={classes.description}>
            O CiViseu, tem como Política, contribuir para a melhoria da
            Segurança Rodoviária, mediante a Prestação de um Serviço de Inspeção
            de Veículos com Competência Técnica e Independente. Para a
            implementação da nossa Política e persecução da nossa missão,
            elegemos três eixos estratégicos de atuação:
          </h5>
          <br></br>
          <br></br>

          <YouTube id="WZZff43IeUI" />
          <br></br>
          <br></br>
        </GridItem>
      </GridContainer>

      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Idoneidade"
              description="Cumprir escrupulosamente todos os requisitos normativos, legais ou da própria organização, bem como os contratualmente estipulados."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Inovação"
              description="Prestar os serviços com recurso a ferramentas e metodologias tecnologicamente avançadas mas simples de utilizar."
              icon={BuildIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Qualidade"
              description="Prestar um serviço de excelência, sempre com enfoque no desenvolvimento profissional dos nossos colaboradores e satisfação dos nossos clientes. "
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
