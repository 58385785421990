import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import axios from "axios";
import { Grid, Typography } from "@material-ui/core";
import { SelectField } from "../../FormFields";
import moment from "moment";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

export default function EscolheHora() {
  const { values: formValues } = useFormikContext();

  const { datePick } = formValues;

  const [horas, updateHoras] = useState([""]);
  const [feriado, setFeriado] = useState(false);
  //POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);

  const useStyles = makeStyles(theme => ({
    typography: {
      padding: theme.spacing(2)
    }
  }));

  const classes = useStyles();

  const handleClick = event => {
    if (feriado) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  var data = moment(datePick).format("YYYY-MM-DD");
  console.log(data);
  const PORT = process.env.PORT || 5000;
  var isLocal;
  const env = `https://civiseu.herokuapp.com`;
  const local = `http://localhost:${PORT}`;

  var date = new Date();

  var minutes = date.getMinutes();
  var hour = date.getHours();
  var agora = "T" + hour + ":" + minutes + ":00";

  if (process.env.NODE_ENV === "production") {
    console.log("zas");
    isLocal = env;
  } else {
    console.log("not zas");
    isLocal = local;
  }
  // const env = process.env.SERVER_APP_API_URL;

  console.log("is Local =" + isLocal);

  useEffect(() => {
    axios.get(`${isLocal}/disp?data=${data}`).then(response => {
      if (response.data == "FERIADO") {
        console.log(formValues);
        setFeriado(true);
        alert("Este dia é feriado. Por favor escolha outro dia.");
        formValues.hourPick = "";
      } else {
        formValues.hourPick = "";
        updateHoras(response.data);
        console.log(agora);
      }
    });
  }, []);

  var filledArray = [];

  for (let index = 0; index < horas.length; index++) {
    filledArray[index] = { value: horas[index], label: horas[index] };
  }
  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async function _openWait() {
    await _sleep(1000);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Escolha uma hora
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectField
            name="hourPick"
            label="Hora*"
            data={filledArray}
            fullWidth
            variant="outlined"
            onClick={handleClick}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Typography className={classes.typography}>
              É FERIADO. POR FAVOR TENTE MARCAR OUTRO DIA.
            </Typography>
          </Popover>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
