import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { Formik, Form } from "formik";
import axios from "axios";

import AddressForm from "./Forms/AddressForm";
import EscolherData from "./Forms/EscolherData";
import EscolherHora from "./Forms/EscolherHora";
import CheckoutSuccess from "./CheckoutSuccess";

import validationSchema from "./FormModel/validationSchema";
import checkoutFormModel from "./FormModel/checkoutFormModel";
import formInitialValues from "./FormModel/formInitialValues";

import useStyles from "./styles";

const steps = ["Escolha um dia", "Escolha uma hora", "Preencha as informações"];
const { formId, formField } = checkoutFormModel;

function _renderStepContent(step) {
  switch (step) {
    case 0:
      return <EscolherData formField={formField} />;
    case 1:
      return <EscolherHora />;
    case 2:
      return <AddressForm formField={formField} />;
    default:
      return <div>Not Found</div>;
  }
}

export default function CheckoutPage() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [codigo, setCodigo] = useState("");
  const [inspValida, setInspValida] = useState(false);

  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const isSecondStep = activeStep === steps.length - 3;

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitDate(values, actions) {
    await _sleep(1000);
    setActiveStep(activeStep + 1);
    actions.setSubmitting(false);
  }

  async function _submitForm(values, actions) {
    await _sleep(1000);
    actions.setSubmitting(false);
    const PORT = process.env.PORT || 5000;
    const env = `https://civiseu.herokuapp.com`;
    const local = `http://localhost:${PORT}`;
    var isLocal;
    if (process.env.NODE_ENV === "production") {
      console.log("zas");
      isLocal = env;
    } else {
      console.log("not zas");
      isLocal = local;
      console.log(isLocal);
    }
    // c
    // const env = process.env.SERVER_APP_API_URL;
    //AXIOS CODE
    const url = `${isLocal}/marcar`;
    const data = values;
    const headers = {
      "Content-Type": "application/json"
    };

    console.log("DATA: " + data);
    axios.post(url, data, headers).then(response => {
      console.log("RESPOSTA" + response.data);
      setCodigo(response.data);
      if (response.data == "INVALIDA") {
        setInspValida(false);
      } else {
        setInspValida(true);
      }
    });

    setActiveStep(activeStep + 1);
  }

  function _handleSubmit(values, actions) {
    if (isSecondStep) {
      _submitDate(values, actions);
    } else if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center">
        Marcar Inspecção
      </Typography>
      <Stepper
        activeStep={activeStep}
        className={classes.stepper}
        alternativeLabel
      >
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? (
          <CheckoutSuccess codigo={codigo} />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={currentValidationSchema}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                {_renderStepContent(activeStep)}

                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={_handleBack} className={classes.button}>
                      Voltar
                    </Button>
                  )}
                  <div className={classes.wrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      {isLastStep ? "Finalizar Marcação" : "Seguinte"}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </React.Fragment>
    </React.Fragment>
  );
}
